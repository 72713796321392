import Web3 from 'web3';
import { Multicall, ContractCallResults, ContractCallContext } from 'ethereum-multicall';
import abiYeti from '../contracts/YetiTown.json';
import abiGame from '../contracts/GameLogicV2.json';
import abiFrxst from '../contracts/FRXST.json';
import axios from 'axios';

const YETI_TOWN_ADDRESS = `${process.env.REACT_APP_YETI_TOWN_ADDRESS}`;
const GAME_LOGIC_ADDRESS = `${process.env.REACT_APP_GAME_LOGIC_ADDRESS}`;
const FRXST_ADDRESS = `${process.env.REACT_APP_FRXST_ADDRESS}`;
const IPFS_ROUTE = `${process.env.REACT_APP_IPFS_ROUTE}`;

export const getUninitiatedTokens = async (
  provider: any,
  walletAddress: String | null
): Promise<string[]> => {
  const web3 = new Web3(provider);

  const multicall = new Multicall({ web3Instance: web3, tryAggregate: true });

  const contractCallContext: ContractCallContext[] = [
    {
      reference: 'contractTown',
      contractAddress: YETI_TOWN_ADDRESS,
      abi: abiYeti,
      calls: [
        { reference: 'balanceOf', methodName: 'balanceOf', methodParameters: [walletAddress] },
        {
          reference: 'isApprovedForAll',
          methodName: 'isApprovedForAll',
          methodParameters: [walletAddress, GAME_LOGIC_ADDRESS]
        }
      ]
    },
    {
      reference: 'contractFrxst',
      contractAddress: FRXST_ADDRESS,
      abi: abiFrxst,
      calls: [
        {
          reference: 'allowance',
          methodName: 'allowance',
          methodParameters: [walletAddress, `${GAME_LOGIC_ADDRESS}`]
        }
      ]
    }
  ];

  const results: ContractCallResults = await multicall.call(contractCallContext);

  const yetisBalance = parseInt(
    results.results.contractTown.callsReturnContext[0].returnValues[0].hex,
    16
  );

  if (yetisBalance === 0) {
    return [];
  }

  const contractCallTokenIDs: ContractCallContext[] = [
    {
      reference: 'contractTown',
      contractAddress: YETI_TOWN_ADDRESS,
      abi: abiYeti,
      calls: Array.from({ length: yetisBalance }, (_, i) => ({
        reference: 'tokenOfOwnerByIndex',
        methodName: 'tokenOfOwnerByIndex',
        methodParameters: [walletAddress, i]
      }))
    }
  ];

  const tokensIDsRes: ContractCallResults = await multicall.call(contractCallTokenIDs);

  const tokensIDs = tokensIDsRes.results.contractTown.callsReturnContext.map((returnContext) =>
    parseInt(returnContext.returnValues[0].hex)
  );

  const gameTokensInfo: { edition: string; date: number; image: string; name: string }[] = [];

  for (const tokenID of tokensIDs) {
    const tokenURI = IPFS_ROUTE + tokenID + '.json';

    let res;
    try {
      res = await axios.get(tokenURI);
    } catch (e) {
      throw new Error('Something wrong with IPFS, network try again');
    }
    const nftInfo = res.data;
    gameTokensInfo.push(nftInfo);
  }

  const contractCallInitiateGameTokens: ContractCallContext[] = [
    {
      reference: 'contractTown',
      contractAddress: GAME_LOGIC_ADDRESS,
      abi: abiGame,
      calls: gameTokensInfo.map((nftInfo) => ({
        reference: 'initiateGame',
        methodName: 'initiateGame',
        methodParameters: [nftInfo.edition]
      }))
    }
  ];

  const initiateGameTokensRes: ContractCallResults = await multicall.call(
    contractCallInitiateGameTokens
  );
  const initiateGameTokens = initiateGameTokensRes.results.contractTown.callsReturnContext.map(
    (returnContext) => returnContext.returnValues[0]
  );

  const unInitatedTokens: string[] = [];

  for (const [index, tokenInitiated] of initiateGameTokens.entries()) {
    if (!tokenInitiated) {
      const { edition } = gameTokensInfo[index];
      unInitatedTokens.push(edition);
    }
  }

  return unInitatedTokens;
};
