import { useEffect, useState, useRef, useLayoutEffect, FC } from 'react';
import { useProvider } from '../../../hooks/provider';
import { useAppSelector, useAppDispatch } from '../../../hooks/redux';
import axios from 'axios';
import Web3 from 'web3';
import YETI_TOWN_CONTRACT from '../../../contracts/YetiTown.json';
import GAME_LOGIC_CONTRACT from '../../../contracts/GameLogicV2.json';
import FRXST_CONTRACT from '../../../contracts/FRXST.json';
import modalMap from '../../../assets/images/modal-map.png';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import 'swiper/swiper-bundle.css';
import 'swiper/swiper.min.css';
import nextArrow from '../../../assets/icons/next-arrow.png';
import prevArrow from '../../../assets/icons/prev-arrow.png';
import SwiperCore, { Navigation } from 'swiper';
import { Multicall, ContractCallResults, ContractCallContext } from 'ethereum-multicall';
import { NftItem } from '../../nftItem';
import { handleShowErrorNotification } from '../../../helpers/showError';
import LoadingSplash from '../../map/splashScreens/LoadingSplash';
import LEVELING_UP from '../../../contracts/levels';
import { getUninitiatedTokens } from '../../../helpers/getUninitiatedTokens';
import { getTokensCount } from '../../../helpers/getTokensCount';
import { setUninitiatedTokens, setTokensInitiated } from '../../../redux/reducers/TokensSlice';

SwiperCore.use([Navigation]);

const BALANCE_MULTIPLIER = 1000000000000000000;

const MAIN_NET_DELAY = 100;

const IPFS_ROUTE = `${process.env.REACT_APP_IPFS_ROUTE}`;
const YETI_TOWN_ADDRESS = `${process.env.REACT_APP_YETI_TOWN_ADDRESS}`;
const GAME_LOGIC_ADDRESS = `${process.env.REACT_APP_GAME_LOGIC_ADDRESS}`;
const FRXST_ADDRESS = `${process.env.REACT_APP_FRXST_ADDRESS}`;

const abiGame: any = GAME_LOGIC_CONTRACT;
const abiFrxst: any = FRXST_CONTRACT;
const abiYeti: any = YETI_TOWN_CONTRACT;

const ChestModal: FC = () => {
  const [isMapImageLoaded, setIsMapImageLoaded] = useState<boolean>(false);
  const [fetching, setFetching] = useState<boolean>(true);

  const [userNftsData, setUserNftsData] = useState<any[]>([]);

  const [selectedStakedTokens, setSelectedStakedTokens] = useState<number[]>([]);
  const [selectedUnstakedToken, setSelectedUnstakedToken] = useState<number | null>(null);

  const [totalStaked, setTotalStaked] = useState<number>(0);
  const [stakedArray, setStakedArray] = useState<any[]>([]);

  const [showUnstakeBtn, setShowUnstakeBtn] = useState<boolean>(false);
  const [showClaimBtn, setShowClaimBtn] = useState<boolean>(false);
  const [showLvlUpBtn, setShowLvlUpBtn] = useState<boolean>(false);

  const [myTotalBalance, setMyTotalBalance] = useState<number>(0);

  const allTokensInitiated = useAppSelector((state) => state.TokensReducer.allTokensInitiated);
  const totalTokensCount = useAppSelector((state) => state.TokensReducer.totalTokensCount);
  const constantsError = useAppSelector((state) => state.ConstantsReducer.error);
  const ignoreTokensInitiating = useAppSelector(
    (state) => state.TokensReducer.ignoreTokensInitiating
  );
  const uninitiatedTokens = useAppSelector((state) => state.TokensReducer.uninitiatedTokens);

  const { MINIMUM_TO_EXIT, REWARD_TIME } = useAppSelector((state) => state.ConstantsReducer);

  const walletAddress = useAppSelector((state) => state.AuthReducer.token);
  const provider = useProvider();

  const dispatch = useAppDispatch();

  const clearSelectedTokens = () => {
    setSelectedUnstakedToken(null);
    setSelectedStakedTokens([]);
  };

  const clearState = () => {
    clearSelectedTokens();
    setStakedArray([]);
    setUserNftsData([]);
    setShowUnstakeBtn(false);
    setShowClaimBtn(false);
    setShowLvlUpBtn(false);
  };

  const handleCheckShowButtons = () => {
    const currentUnixTime = new Date().getTime() / 1000;
    const canBeUnstaked = selectedStakedTokens.every((tokenId) => {
      const currentToken = stakedArray.find((nft) => {
        return nft.edition === tokenId;
      });
      return currentToken.unstakeTime < currentUnixTime;
    });
    const canBeClaimed = selectedStakedTokens.every((tokenId) => {
      const currentToken = stakedArray.find((nft) => {
        return nft.edition === tokenId;
      });
      return currentToken.claimTimeHence < currentUnixTime;
    });
    setShowClaimBtn(canBeClaimed);
    setShowUnstakeBtn(canBeUnstaked);
  };

  useLayoutEffect(() => {
    if (selectedStakedTokens.length > 0 && stakedArray.length > 0) {
      handleCheckShowButtons();
      const setCountdown: ReturnType<typeof setInterval> = setInterval(() => {
        handleCheckShowButtons();
      }, 1000);
      return () => clearInterval(setCountdown);
    }
  }, [selectedStakedTokens]);

  const getStakedTokens = async () => {
    if (provider && walletAddress && !constantsError) {
      try {
        const web3 = new Web3(provider);

        const multicall = new Multicall({ web3Instance: web3, tryAggregate: true });

        const contractCallContext: ContractCallContext[] = [
          {
            reference: 'contractGameLogic',
            contractAddress: GAME_LOGIC_ADDRESS,
            abi: abiGame,
            calls: [
              {
                reference: 'getStakedTokens',
                methodName: 'getStakedTokens',
                methodParameters: [walletAddress]
              }
            ]
          }
        ];

        const results: ContractCallResults = await multicall.call(contractCallContext);
        const stakedTokens =
          results.results.contractGameLogic.callsReturnContext[0].returnValues.map((returnValue) =>
            parseInt(returnValue.hex)
          );

        setTotalStaked(stakedTokens.length);

        if (stakedTokens.length > 0) {
          const contractCallTokenInfo: ContractCallContext[] = [
            {
              reference: 'palace',
              contractAddress: GAME_LOGIC_ADDRESS,
              abi: abiGame,
              calls: stakedTokens.map((stakedToken) => ({
                reference: 'palace',
                methodName: 'palace',
                methodParameters: [stakedToken]
              }))
            },
            {
              reference: 'fighters',
              contractAddress: GAME_LOGIC_ADDRESS,
              abi: abiGame,
              calls: stakedTokens.map((stakedToken) => ({
                reference: 'fighters',
                methodName: 'fighters',
                methodParameters: [stakedToken]
              }))
            },
            {
              reference: 'levels',
              contractAddress: GAME_LOGIC_ADDRESS,
              abi: abiGame,
              calls: stakedTokens.map((stakedToken) => ({
                reference: 'levels',
                methodName: 'levels',
                methodParameters: [stakedToken]
              }))
            },
            {
              reference: 'experience',
              contractAddress: GAME_LOGIC_ADDRESS,
              abi: abiGame,
              calls: stakedTokens.map((stakedToken) => ({
                reference: 'experience',
                methodName: 'experience',
                methodParameters: [stakedToken]
              }))
            }
          ];

          const stakeTokenInfo: ContractCallResults = await multicall.call(contractCallTokenInfo);
          const inPalaceArrRes: {
            tokenID: number;
            claimTime: number;
            activityID: number;
            stakeTime: number;
          }[] = stakeTokenInfo.results.palace.callsReturnContext.map((returnContext) => {
            return {
              tokenID: returnContext.returnValues[0],
              claimTime: parseInt(returnContext.returnValues[1].hex, 16),
              activityID: returnContext.returnValues[2],
              stakeTime: parseInt(returnContext.returnValues[4].hex, 16)
            };
          });
          const inFightersArrRes: {
            tokenID: number;
            claimTime: number;
            activityID: number;
            stakeTime: number;
          }[] = stakeTokenInfo.results.fighters.callsReturnContext.map((returnContext) => {
            return {
              tokenID: returnContext.returnValues[0],
              claimTime: parseInt(returnContext.returnValues[1].hex, 16),
              activityID: returnContext.returnValues[2],
              stakeTime: parseInt(returnContext.returnValues[4].hex, 16)
            };
          });
          const levelsArrRes = stakeTokenInfo.results.levels.callsReturnContext.map(
            (returnContext) => {
              return returnContext.returnValues;
            }
          );
          const experienceArrRes = stakeTokenInfo.results.experience.callsReturnContext.map(
            (returnContext) => {
              return parseInt(returnContext.returnValues[0].hex, 16);
            }
          );

          const currentStakedTokens = [];

          for (const [index, stakedToken] of stakedTokens.entries()) {
            const tokenURI = IPFS_ROUTE + stakedToken + '.json';

            let res;
            try {
              res = await axios.get(tokenURI);
            } catch (e) {
              throw new Error('Something wrong with IPFS, network try again');
            }
            const nftInfo = res.data;
            nftInfo.image = nftInfo.image.replace('ipfs://', 'https://ipfs.io/ipfs/');

            nftInfo.level = levelsArrRes[index];
            nftInfo.experience = experienceArrRes[index];

            const inPalace = inPalaceArrRes.find((inPalace) => inPalace.tokenID === stakedToken);
            const inFighting = inFightersArrRes.find(
              (inFighting) => inFighting.tokenID === stakedToken
            );
            if (inPalace?.tokenID === stakedToken) {
              const { claimTime, stakeTime } = inPalace;
              const unstakeTime =
                (claimTime > stakeTime ? claimTime : stakeTime) + MINIMUM_TO_EXIT + MAIN_NET_DELAY;
              const claimTimeHence = claimTime + REWARD_TIME + MAIN_NET_DELAY;
              const newNftInfo = { ...nftInfo, claimTimeHence, unstakeTime };
              currentStakedTokens.push(newNftInfo);
            }
            if (inFighting?.tokenID === stakedToken) {
              const { claimTime, stakeTime } = inFighting;
              const unstakeTime =
                (claimTime > stakeTime ? claimTime : stakeTime) + MINIMUM_TO_EXIT + MAIN_NET_DELAY;
              const claimTimeHence = claimTime + REWARD_TIME + MAIN_NET_DELAY;
              const newNftInfo = { ...nftInfo, claimTimeHence, unstakeTime };
              currentStakedTokens.push(newNftInfo);
            }
          }
          setStakedArray(currentStakedTokens);
        }
      } catch (error) {
        handleShowErrorNotification(error);
      }
    }
  };

  const getUserTokens: any = async () => {
    if (provider && walletAddress && !constantsError) {
      try {
        clearState();
        const web3 = new Web3(provider);

        const multicall = new Multicall({ web3Instance: web3, tryAggregate: true });

        const contractCallContext: ContractCallContext[] = [
          {
            reference: 'contractTown',
            contractAddress: YETI_TOWN_ADDRESS,
            abi: abiYeti,
            calls: [
              { reference: 'balanceOf', methodName: 'balanceOf', methodParameters: [walletAddress] }
            ]
          },
          {
            reference: 'contractFrxst',
            contractAddress: FRXST_ADDRESS,
            abi: abiFrxst,
            calls: [
              { reference: 'balanceOf', methodName: 'balanceOf', methodParameters: [walletAddress] }
            ]
          }
        ];

        const results: ContractCallResults = await multicall.call(contractCallContext);

        const yetisBalance = parseInt(
          results.results.contractTown.callsReturnContext[0].returnValues[0].hex,
          16
        );
        const myTotalBalance =
          parseInt(results.results.contractFrxst.callsReturnContext[0].returnValues[0].hex, 16) /
          BALANCE_MULTIPLIER;

        setMyTotalBalance(myTotalBalance);

        if (yetisBalance > 0) {
          const contractCallTokenIDs: ContractCallContext[] = [
            {
              reference: 'contractTown',
              contractAddress: YETI_TOWN_ADDRESS,
              abi: abiYeti,
              calls: Array.from({ length: yetisBalance }, (_, i) => ({
                reference: 'tokenOfOwnerByIndex',
                methodName: 'tokenOfOwnerByIndex',
                methodParameters: [walletAddress, i]
              }))
            }
          ];

          const tokensIDsRes: ContractCallResults = await multicall.call(contractCallTokenIDs);
          const tokensIDs = tokensIDsRes.results.contractTown.callsReturnContext.map(
            (returnContext) => parseInt(returnContext.returnValues[0].hex)
          );

          const gameTokensInfo: {
            edition: string;
            date: number;
            image: string;
            name: string;
            level?: number;
            experience?: number;
          }[] = [];
          for (const tokenID of tokensIDs) {
            if (uninitiatedTokens.includes(tokenID)) {
              continue;
            }
            const tokenURI = IPFS_ROUTE + tokenID + '.json';

            let res;
            try {
              res = await axios.get(tokenURI);
            } catch (e) {
              throw new Error('Something wrong with IPFS, network try again');
            }
            const nftInfo = res.data;
            gameTokensInfo.push(nftInfo);
          }

          const contractCallTokenInfo: ContractCallContext[] = [
            {
              reference: 'levels',
              contractAddress: GAME_LOGIC_ADDRESS,
              abi: abiGame,
              calls: gameTokensInfo.map((nftInfo) => ({
                reference: 'levels',
                methodName: 'levels',
                methodParameters: [nftInfo.edition]
              }))
            },
            {
              reference: 'experience',
              contractAddress: GAME_LOGIC_ADDRESS,
              abi: abiGame,
              calls: gameTokensInfo.map((nftInfo) => ({
                reference: 'experience',
                methodName: 'experience',
                methodParameters: [nftInfo.edition]
              }))
            }
          ];

          const tokenInfoRes: ContractCallResults = await multicall.call(contractCallTokenInfo);
          const tokensLevels = tokenInfoRes.results.levels.callsReturnContext.map(
            (returnContext) => returnContext.returnValues[0]
          );
          const tokensExps = tokenInfoRes.results.experience.callsReturnContext.map(
            (returnContext) => parseInt(returnContext.returnValues[0].hex)
          );

          for (const [index, gameTokenInfo] of gameTokensInfo.entries()) {
            gameTokensInfo[index].image = gameTokenInfo.image.replace(
              'ipfs://',
              'https://ipfs.io/ipfs/'
            );
            gameTokensInfo[index].level = tokensLevels[index];
            gameTokensInfo[index].experience = tokensExps[index];
          }

          setUserNftsData(gameTokensInfo);
        }
      } catch (error) {
        handleShowErrorNotification(error);
      }
    }
  };

  const navigationUnstakedPrevRef = useRef(null);
  const navigationUnstakedNextRef = useRef(null);
  const navigationStakedPrevRef = useRef(null);
  const navigationStakedNextRef = useRef(null);

  const handleClaim = async () => {
    if (provider) {
      setFetching(true);
      const abiGame: any = GAME_LOGIC_CONTRACT;
      const web3 = new Web3(provider);
      const contractGameLogic = await new web3.eth.Contract(abiGame, GAME_LOGIC_ADDRESS);
      try {
        await contractGameLogic.methods
          .claimMany(selectedStakedTokens, false)
          .send({ from: walletAddress });
      } catch (error) {
        handleShowErrorNotification(error);
      } finally {
        setFetching(false);
      }
      initRequests();
    }
  };

  const handleUnStake = async () => {
    if (provider) {
      setFetching(true);
      const abiGame: any = GAME_LOGIC_CONTRACT;
      const web3 = new Web3(provider);
      const contractGameLogic = await new web3.eth.Contract(abiGame, GAME_LOGIC_ADDRESS);
      try {
        await contractGameLogic.methods
          .claimMany(selectedStakedTokens, true)
          .send({ from: walletAddress });
      } catch (error) {
        handleShowErrorNotification(error);
      } finally {
        setFetching(false);
      }
      clearSelectedTokens();
      initRequests();
    }
  };

  const handleLevelUp = async () => {
    if (provider) {
      const web3 = new Web3(provider);
      const contractGameLogic = await new web3.eth.Contract(abiGame, GAME_LOGIC_ADDRESS);
      setFetching(true);
      try {
        await contractGameLogic.methods
          .levelup(selectedUnstakedToken)
          .send({ from: walletAddress });
      } catch (error) {
        handleShowErrorNotification(error);
      } finally {
        setFetching(false);
      }
      clearSelectedTokens();
      initRequests();
    }
  };

  const initRequests = async () => {
    clearState();
    setFetching(true);
    try {
      const { unstakedTokensCount, stakedTokensCount } = await getTokensCount(
        provider,
        walletAddress
      );
      const newTotalTokensCount = unstakedTokensCount + stakedTokensCount;
      if (totalTokensCount !== newTotalTokensCount) {
        const unInitiatedTokens = await getUninitiatedTokens(provider, walletAddress);
        if (unInitiatedTokens.length > 0) {
          dispatch(setTokensInitiated(false));
          dispatch(setUninitiatedTokens(unInitiatedTokens));
        }
      }
      await getUserTokens();
      await getStakedTokens();
    } catch (error) {
      handleShowErrorNotification(error);
    } finally {
      setFetching(false);
    }
  };

  useEffect(() => {
    if (provider && walletAddress) {
      initRequests();
    }
  }, [
    provider,
    walletAddress,
    allTokensInitiated,
    ignoreTokensInitiating,
    MINIMUM_TO_EXIT,
    REWARD_TIME
  ]);
  const checkCouldNftLvlUp = (tokenId: number): boolean => {
    const selectedNft = userNftsData.find((nft: any) => nft.edition === tokenId);

    const level: number = selectedNft.level;

    if (!LEVELING_UP[level]) return false;
    if (LEVELING_UP[level].EXP > parseInt(selectedNft.experience, 10)) return false;
    if (LEVELING_UP[level].FRXST > myTotalBalance) return false;
    return true;
  };

  const handleSelectUnstakedToken = (tokenId: number) => {
    if (selectedUnstakedToken === tokenId) {
      setSelectedUnstakedToken(null);
    } else {
      setSelectedUnstakedToken(tokenId);
    }
    const canLvlUp = checkCouldNftLvlUp(tokenId);
    setShowLvlUpBtn(canLvlUp);
    setSelectedStakedTokens([]);
  };

  const handleSelectStakedToken = (tokenId: number) => {
    let updatedStakedTokens = [];
    if (selectedStakedTokens?.includes(tokenId)) {
      updatedStakedTokens = selectedStakedTokens.filter((token) => {
        return token !== tokenId;
      });
      setSelectedStakedTokens(updatedStakedTokens);
    } else {
      updatedStakedTokens = [...selectedStakedTokens, tokenId];
      setSelectedStakedTokens(updatedStakedTokens);
    }
    setSelectedUnstakedToken(null);
  };

  return (
    <>
      {fetching && <LoadingSplash />}
      {!fetching && (
        <div className={`modal-map-field`}>
          <div className="modal-map-field__content">
            <div className="modal-map-field__table">
              <div className="modal-map-table chest-table">
                <div className="modal-map-table__title">Your Collector’s Chest</div>
                <div className="modal-map-table__header">
                  <div className="modal-map-table__header-row">
                    <div>
                      total staked: <span>{totalStaked}</span>
                    </div>
                    <div>
                      $frxst balance: <span>{Math.round(myTotalBalance)}</span>
                    </div>
                  </div>
                  <div className="modal-map-table__header-row">
                    <div>
                      total unstaked: <span>{userNftsData.length}</span>
                    </div>
                  </div>
                </div>
                {stakedArray && stakedArray.length > 0 && (
                  <>
                    <div className="modal-map-table__row-title">
                      staked
                      {selectedStakedTokens.length > 0 &&
                        ` (${selectedStakedTokens.length} selected):`}
                    </div>
                    <div className="modal-map-table__content modal-map-field__row">
                      {stakedArray.length > 4 && (
                        <>
                          <button
                            className="button-prev"
                            type="button"
                            ref={navigationStakedPrevRef}
                          >
                            <img src={prevArrow} alt="" />
                          </button>
                          <button
                            className="button-next"
                            type="button"
                            ref={navigationStakedNextRef}
                          >
                            <img src={nextArrow} alt="" />
                          </button>
                        </>
                      )}
                      <Swiper
                        slidesPerView={4}
                        spaceBetween={0}
                        watchOverflow={true}
                        navigation={{
                          nextEl: navigationStakedNextRef.current,
                          prevEl: navigationStakedPrevRef.current
                        }}
                        updateOnImagesReady
                      >
                        {stakedArray?.map((nft: any) => {
                          return (
                            <SwiperSlide key={nft.edition}>
                              <div className="chest__row">
                                <NftItem
                                  onClick={() => handleSelectStakedToken(nft.edition)}
                                  isSelected={selectedStakedTokens.includes(nft.edition)}
                                  nft={nft}
                                  showTimer={true}
                                />
                              </div>
                            </SwiperSlide>
                          );
                        })}
                      </Swiper>
                    </div>
                  </>
                )}
                {userNftsData.length !== 0 && (
                  <>
                    <div className="modal-map-table__row-title">unstaked:</div>
                    <div
                      style={{ maxHeight: '20vw' }}
                      className={`modal-map-table__content modal-map-field__row`}
                    >
                      {userNftsData.length > 4 && (
                        <>
                          <button
                            className="button-prev"
                            type="button"
                            ref={navigationUnstakedPrevRef}
                          >
                            <img src={prevArrow} alt="" />
                          </button>
                          <button
                            className="button-next"
                            type="button"
                            ref={navigationUnstakedNextRef}
                          >
                            <img src={nextArrow} alt="" />
                          </button>
                        </>
                      )}
                      <Swiper
                        slidesPerView={4}
                        spaceBetween={0}
                        watchOverflow={true}
                        navigation={{
                          nextEl: navigationUnstakedNextRef.current,
                          prevEl: navigationUnstakedPrevRef.current
                        }}
                        updateOnImagesReady
                      >
                        {userNftsData?.map((nft: any) => {
                          return (
                            <SwiperSlide key={nft.edition}>
                              <div className="chest__row">
                                <NftItem
                                  onClick={() => handleSelectUnstakedToken(nft.edition)}
                                  isSelected={selectedUnstakedToken === nft.edition}
                                  nft={nft}
                                  showTimer={false}
                                />
                              </div>
                            </SwiperSlide>
                          );
                        })}
                      </Swiper>
                    </div>
                  </>
                )}
                {userNftsData.length === 0 && stakedArray.length === 0 && (
                  <div className="text-center chest__empty-text">Your chest is empty</div>
                )}
                <div className="modal-map-table__buttons">
                  <button
                    className={`btn-outline ${
                      selectedStakedTokens.length > 0 ? '' : 'invisible'
                    }`}
                    type="button"
                    onClick={handleClaim}
                    disabled={!showClaimBtn}
                  >
                    claim
                  </button>
                  <div></div>
                  <button
                    className={`btn-outline ${
                      selectedUnstakedToken ? '' : 'invisible'
                    }`}
                    type="button"
                    onClick={handleLevelUp}
                    disabled={!showLvlUpBtn}
                  >
                    Level up
                  </button>
                  <div></div>
                  <button
                    className={`btn-outline ${
                      selectedStakedTokens.length > 0 ? '' : 'invisible'
                    }`}
                    type="button"
                    onClick={handleUnStake}
                    disabled={!showUnstakeBtn}
                  >
                    claim & unstake
                  </button>
                </div>
              </div>
            </div>
            <img
              src={modalMap}
              alt=""
              className={`modal-map-field__img`}
              onLoad={() => setIsMapImageLoaded(true)}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default ChestModal;
