import gatherNotificationImg from '../../../assets/images/gather-notification.gif';

const GatherSplash = () => {
  return (
    <div className="full-width-notification">
      <img className="full-width-notification__img" src={gatherNotificationImg} alt="Gather" />
    </div>
  );
};

export default GatherSplash;
