import { FC, useEffect, useState } from 'react';
import './style.scss';
import discordLogo from '../../assets/icons/social-discord.png';
import openseaLogo from '../../assets/icons/social-opensea.png';
import twitterLogo from '../../assets/icons/social-twitter.png';
import looksRareLogo from '../../assets/icons/looksrare-icon.png';
import { useAppDispatch, useAppSelector } from 'app/hooks/redux';
import { getConnection } from '../../redux/reducers/ActionCreators';
import { logout } from '../../redux/reducers/AuthSlice';
import { useProvider } from '../../hooks/provider';
import DisabledMetaMask from 'app/components/dialog/DisabledMetaMask';
import ModalContainer from '../../containers/modal';

const Header: FC = () => {
  const token = useAppSelector((state) => state.AuthReducer.token);
  const displayToken = `${token && token?.slice(0, 6) + '...' + token?.slice(-6)}`;

  const dispatch = useAppDispatch();

  const [disabledMetaMaskModalOpen, setDisabledMetaMaskModalOpen] = useState<boolean>(false);

  const handleGetConnection = () => dispatch(getConnection());
  const handleLogout = () => dispatch(logout());

  const [isHeaderScrolled, setIsHeaderScrolled] = useState(false);
  const [isHeaderOpened, setIsHeaderOpened] = useState(false);

  const provider = useProvider();

  useEffect(() => {
    window.addEventListener('scroll', () => {
      const { pageYOffset } = window;
      if (pageYOffset > 50) {
        setIsHeaderScrolled(true);
      } else {
        setIsHeaderScrolled(false);
      }
    });
  }, []);

  const withMetaMaskCheck = (callback: () => any) => {
    if (!provider) {
      setDisabledMetaMaskModalOpen(true);
    } else {
      callback && callback();
    }
  };

  const handleCloseDisabledMetaMaskModal = () => {
    setDisabledMetaMaskModalOpen(false);
  };

  return (
    <>
      <header
        className={`header ${isHeaderScrolled ? 'header--scroll' : ''} ${
          isHeaderOpened ? 'header--opened' : ''
        }`}
      >
        <div className="page-container page-container--xl">
          <div className="header__content">
            <nav className="header__nav header-nav">
              <a
                href="https://docs.yetitown.com/game"
                onClick={() => setIsHeaderOpened(!isHeaderOpened)}
                target="_blank"
                className="header-nav__item"
              >
                How To Play
              </a>
              <a
                href="#origin"
                onClick={() => setIsHeaderOpened(!isHeaderOpened)}
                className="header-nav__item"
              >
                Origin
              </a>
              <a
                href="#stake"
                onClick={() => setIsHeaderOpened(!isHeaderOpened)}
                className="header-nav__item"
              >
                Stake
              </a>
              <a
                href="#roadmap"
                onClick={() => setIsHeaderOpened(!isHeaderOpened)}
                className="header-nav__item"
              >
                roadmap
              </a>
              {/* <a
                href="#team"
                onClick={() => setIsHeaderOpened(!isHeaderOpened)}
                className="header-nav__item"
              >
                Team
              </a> */}
              <a className="header-nav__item header-nav__item-eyes-cursor">Market</a>
            </nav>
            <div className="header__social">
              {/* <a href="https://discord.gg/yetitown" target="_blank">
                <img src={discordLogo} alt="Logo" />
                <span>Discord</span>
              </a> */}
              <a href="https://twitter.com/YetiTownNFT" target="_blank">
                <img src={twitterLogo} alt="Logo" />
                <span>Twitter</span>
              </a>
              <a href="https://opensea.io/collection/yeti-town" target="_blank">
                <img src={openseaLogo} alt="Logo" />
                <span>Open Sea</span>
              </a>
              <a
                href="https://looksrare.org/collections/0x65c5493E6D4D7bf2dA414571EB87ed547Eb0AbeD"
                target="_blank"
              >
                <img src={looksRareLogo} alt="Logo" />
                <span>Looks rare</span>
              </a>
            </div>
            {!token && (
              <button
                type="button"
                className="connect-btn m-0"
                onClick={() => withMetaMaskCheck(handleGetConnection)}
              >
                connect
              </button>
            )}
            {token && (
              <>
                <button
                  type="button"
                  title="Disconnect"
                  className="connect-btn m-0"
                  onClick={handleLogout}
                >
                  {`${token && displayToken}`}
                </button>
              </>
            )}
          </div>
        </div>
      </header>
      <div className="header-backdrop" onClick={() => setIsHeaderOpened(!isHeaderOpened)}></div>
      <button
        type="button"
        className="burger-btn"
        onClick={() => setIsHeaderOpened(!isHeaderOpened)}
      >
        <span></span>
        <span></span>
        <span></span>
      </button>
      <ModalContainer
        className={'dialog-modal-wrapper'}
        isOpen={disabledMetaMaskModalOpen}
        closeModal={() => {
          handleCloseDisabledMetaMaskModal();
        }}
      >
        <DisabledMetaMask />
      </ModalContainer>
    </>
  );
};

export default Header;
