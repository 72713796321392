import './styles.scss';
import { useEffect, useState, useRef, useLayoutEffect, FC } from 'react';
import GAME_LOGIC_CONTRACT from '../../contracts/GameLogicV2.json';
import YETI_TOWN_CONTRACT from '../../contracts/YetiTown.json';
import FRXST_CONTRACT from '../../contracts/FRXST.json';
import Web3 from 'web3';
import { useProvider } from '../../hooks/provider';
import { useAppSelector, useAppDispatch } from '../../hooks/redux';
import axios from 'axios';
import modalMap from '../../assets/images/modal-map.png';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import SwiperCore, { Navigation } from 'swiper';
import nextArrow from '../../assets/icons/next-arrow.png';
import prevArrow from '../../assets/icons/prev-arrow.png';
import { Store } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import './notification.scss';
import 'animate.css';
import { Multicall, ContractCallResults, ContractCallContext } from 'ethereum-multicall';
import { NftItem } from '../nftItem';
import { handleShowErrorNotification } from '../../helpers/showError';
import FightSplash from './splashScreens/FightSplash';
import HuntSplash from './splashScreens/HuntSplash';
import GatherSplash from './splashScreens/GatherSplash';
import LoadingSplash from './splashScreens/LoadingSplash';
import LEVELING_UP from 'app/contracts/levels';
import { ACTION_IDS } from 'app/contracts/actions';
import { getUninitiatedTokens } from '../../helpers/getUninitiatedTokens';
import { getTokensCount } from '../../helpers/getTokensCount';
import { setUninitiatedTokens, setTokensInitiated } from '../../redux/reducers/TokensSlice';
import { Tooltip } from '../nftItem/Tooltip';

SwiperCore.use([Navigation]);

const YETI_TOWN_ADDRESS = `${process.env.REACT_APP_YETI_TOWN_ADDRESS}`;
const GAME_LOGIC_ADDRESS = `${process.env.REACT_APP_GAME_LOGIC_ADDRESS}`;
const FRXST_ADDRESS = `${process.env.REACT_APP_FRXST_ADDRESS}`;
const IPFS_ROUTE = `${process.env.REACT_APP_IPFS_ROUTE}`;

const abiGame: any = GAME_LOGIC_CONTRACT;
const abiFrxst: any = FRXST_CONTRACT;
const abiYeti: any = YETI_TOWN_CONTRACT;

const BALANCE_MULTIPLIER = 1000000000000000000;

const MAIN_NET_DELAY = 100;

const FRXST_RATES = [
  [50, 60, 4],
  [90, 135, 10],
  [130, 195, 26]
];

const EXP_RATES = [
  [80, 100, 120],
  [96, 120, 144],
  [120, 150, 180]
];

interface IMapModal {
  selectedActionId: null | number;
}

const MapModal: FC<IMapModal> = ({ selectedActionId }) => {
  const [isMapImageLoaded, setIsMapImageLoaded] = useState(false);
  const [fetching, setFetching] = useState(true);
  const [selectedUnstakedTokens, setSelectedUnstakedTokens] = useState<number[]>([]);
  const [selectedStakedTokens, setSelectedStakedTokens] = useState<number[]>([]);
  const [selectedInHospitalToken, setSelectedInHospitalToken] = useState<number | null>(null);

  const [stakedArray, setStakedArray] = useState<any[]>([]);

  const [totalStaked, setTotalStaked] = useState<number>(0);

  const [showUnstakeBtn, setShowUnstakeBtn] = useState<boolean>(false);
  const [showClaimSickBtn, setShowClaimSickBtn] = useState<boolean>(false);
  const [showHealBtn, setShowHealBtn] = useState<boolean>(false);
  const [showClaimBtn, setShowClaimBtn] = useState<boolean>(false);

  const [userNftsData, setUserNftsData] = useState<any[]>([]);

  const [myTotalBalance, setMyTotalBalance] = useState<number>(0);

  const allTokensInitiated = useAppSelector((state) => state.TokensReducer.allTokensInitiated);
  const totalTokensCount = useAppSelector((state) => state.TokensReducer.totalTokensCount);
  const ignoreTokensInitiating = useAppSelector(
    (state) => state.TokensReducer.ignoreTokensInitiating
  );
  const constantsError = useAppSelector((state) => state.ConstantsReducer.error);
  const uninitiatedTokens = useAppSelector((state) => state.TokensReducer.uninitiatedTokens);

  const { MINIMUM_TO_EXIT, INJURY_TIME, REWARD_TIME } = useAppSelector(
    (state) => state.ConstantsReducer
  );

  const provider = useProvider();
  const dispatch = useAppDispatch();

  const handleCheckShowButtons = () => {
    const currentUnixTime = new Date().getTime() / 1000;
    if (selectedActionId !== ACTION_IDS.HEAL) {
      const canBeUnstaked = selectedStakedTokens.every((tokenId) => {
        const currentToken = stakedArray.find((nft) => {
          return nft.edition === tokenId;
        });
        return currentToken.unstakeTime < currentUnixTime;
      });
      const canBeClaimed = selectedStakedTokens.every((tokenId) => {
        const currentToken = stakedArray.find((nft) => {
          return nft.edition === tokenId;
        });
        return currentToken.claimTimeHence < currentUnixTime;
      });
      setShowClaimBtn(canBeClaimed);
      setShowUnstakeBtn(canBeUnstaked);
    } else if (selectedActionId === ACTION_IDS.HEAL) {
      const currentNft = stakedArray.find((nft) => nft.edition === selectedInHospitalToken);
      const canBeSickClaimed = currentNft.claimTimeHence < currentUnixTime;

      const canBeHealed = myTotalBalance > currentNft.heal_cost;
      setShowClaimSickBtn(canBeSickClaimed);
      setShowHealBtn(!canBeSickClaimed && canBeHealed);
    }
  };

  useLayoutEffect(() => {
    if ((stakedArray.length > 0, selectedStakedTokens.length > 0 || selectedInHospitalToken)) {
      handleCheckShowButtons();
      const setCountdown: ReturnType<typeof setInterval> = setInterval(() => {
        handleCheckShowButtons();
      }, 1000);
      return () => clearInterval(setCountdown);
    }
  }, [selectedStakedTokens, selectedInHospitalToken]);

  const clearSelectedTokens = () => {
    setSelectedInHospitalToken(null);
    setSelectedUnstakedTokens([]);
    setSelectedStakedTokens([]);
  };

  const clearState = () => {
    clearSelectedTokens();
    setShowUnstakeBtn(false);
    setShowClaimSickBtn(false);
    setShowHealBtn(false);
    setStakedArray([]);
    setUserNftsData([]);
  };

  useEffect(() => {
    return () => clearState();
  }, []);

  const walletAddress = useAppSelector((state) => state.AuthReducer.token);

  const getStakedTokens = async () => {
    if (provider && walletAddress && !constantsError) {
      try {
        const web3 = new Web3(provider);

        const multicall = new Multicall({ web3Instance: web3, tryAggregate: true });
        const contractGameLogic = await new web3.eth.Contract(abiGame, `${GAME_LOGIC_ADDRESS}`);

        const contractCallContext: ContractCallContext[] = [
          {
            reference: 'contractGameLogic',
            contractAddress: GAME_LOGIC_ADDRESS,
            abi: abiGame,
            calls: [
              {
                reference: 'getStakedTokens',
                methodName: 'getStakedTokens',
                methodParameters: [walletAddress]
              }
            ]
          }
        ];

        const results: ContractCallResults = await multicall.call(contractCallContext);
        const stakedTokens =
          results.results.contractGameLogic.callsReturnContext[0].returnValues.map((returnValue) =>
            parseInt(returnValue.hex)
          );

        setTotalStaked(stakedTokens.length);

        if (stakedTokens.length > 0) {
          let method = '';
          let contractCallTokenInfo: ContractCallContext[] | null = null;

          if (selectedActionId === ACTION_IDS.FIGHT) {
            method = 'fighters';
            contractCallTokenInfo = [
              {
                reference: method,
                contractAddress: GAME_LOGIC_ADDRESS,
                abi: abiGame,
                calls: stakedTokens.map((stakedToken) => ({
                  reference: method,
                  methodName: method,
                  methodParameters: [stakedToken]
                }))
              },
              {
                reference: 'levels',
                contractAddress: GAME_LOGIC_ADDRESS,
                abi: abiGame,
                calls: stakedTokens.map((stakedToken) => ({
                  reference: 'levels',
                  methodName: 'levels',
                  methodParameters: [stakedToken]
                }))
              },
              {
                reference: 'experience',
                contractAddress: GAME_LOGIC_ADDRESS,
                abi: abiGame,
                calls: stakedTokens.map((stakedToken) => ({
                  reference: 'experience',
                  methodName: 'experience',
                  methodParameters: [stakedToken]
                }))
              }
            ];
          } else if (selectedActionId === ACTION_IDS.HEAL) {
            method = 'hospital';
            contractCallTokenInfo = [
              {
                reference: method,
                contractAddress: GAME_LOGIC_ADDRESS,
                abi: abiGame,
                calls: stakedTokens.map((stakedToken) => ({
                  reference: method,
                  methodName: method,
                  methodParameters: [stakedToken]
                }))
              },
              {
                reference: 'levels',
                contractAddress: GAME_LOGIC_ADDRESS,
                abi: abiGame,
                calls: stakedTokens.map((stakedToken) => ({
                  reference: 'levels',
                  methodName: 'levels',
                  methodParameters: [stakedToken]
                }))
              },
              {
                reference: 'experience',
                contractAddress: GAME_LOGIC_ADDRESS,
                abi: abiGame,
                calls: stakedTokens.map((stakedToken) => ({
                  reference: 'experience',
                  methodName: 'experience',
                  methodParameters: [stakedToken]
                }))
              }
            ];
          } else {
            method = 'palace';
            contractCallTokenInfo = [
              {
                reference: method,
                contractAddress: GAME_LOGIC_ADDRESS,
                abi: abiGame,
                calls: stakedTokens.map((stakedToken) => ({
                  reference: method,
                  methodName: method,
                  methodParameters: [stakedToken]
                }))
              },
              {
                reference: 'levels',
                contractAddress: GAME_LOGIC_ADDRESS,
                abi: abiGame,
                calls: stakedTokens.map((stakedToken) => ({
                  reference: 'levels',
                  methodName: 'levels',
                  methodParameters: [stakedToken]
                }))
              },
              {
                reference: 'experience',
                contractAddress: GAME_LOGIC_ADDRESS,
                abi: abiGame,
                calls: stakedTokens.map((stakedToken) => ({
                  reference: 'experience',
                  methodName: 'experience',
                  methodParameters: [stakedToken]
                }))
              }
            ];
          }

          if (!contractCallTokenInfo) {
            return;
          }

          const stakedTokensInfo: ContractCallResults = await multicall.call(contractCallTokenInfo);
          const stakedArrRes: {
            tokenID: number;
            claimTime: number;
            activityID: number;
            stakeTime: number | null;
          }[] = stakedTokensInfo.results[method].callsReturnContext.map((returnContext) => {
            return {
              tokenID: returnContext.returnValues[0],
              claimTime: parseInt(returnContext.returnValues[1].hex, 16),
              activityID: returnContext.returnValues[2],
              stakeTime:
                selectedActionId !== ACTION_IDS.HEAL
                  ? parseInt(returnContext.returnValues[4].hex, 16)
                  : null
            };
          });
          const levelsArrRes = stakedTokensInfo.results.levels.callsReturnContext.map(
            (returnContext) => {
              return returnContext.returnValues;
            }
          );
          const experienceArrRes = stakedTokensInfo.results.experience.callsReturnContext.map(
            (returnContext) => {
              return parseInt(returnContext.returnValues[0].hex, 16);
            }
          );

          const currentStakedTokens: any = [];

          for (const [index, stakedToken] of stakedTokens.entries()) {
            if (stakedArrRes[index].tokenID === stakedToken) {
              if (method === 'palace' && stakedArrRes[index].activityID !== selectedActionId) {
                continue;
              }
              const tokenURI = IPFS_ROUTE + stakedToken + '.json';
              let res;
              try {
                res = await axios.get(tokenURI);
              } catch (e) {
                throw new Error('Something wrong with IPFS, network try again');
              }

              const nftInfo = res?.data;
              nftInfo.image = nftInfo.image.replace('ipfs://', 'https://ipfs.io/ipfs/');
              nftInfo.level = levelsArrRes[index];
              nftInfo.experience = experienceArrRes[index];
              const { claimTime, stakeTime } = stakedArrRes[index];
              const unstakeTime =
                stakeTime &&
                (claimTime > stakeTime ? claimTime : stakeTime) + MINIMUM_TO_EXIT + MAIN_NET_DELAY;
              const claimTimeHence =
                claimTime +
                (selectedActionId === ACTION_IDS.HEAL ? INJURY_TIME : REWARD_TIME) +
                MAIN_NET_DELAY;

              // We can get it from backend api not from smart contracts
              const rarity = await contractGameLogic.methods.tokenRarity(nftInfo.edition).call();
              nftInfo.rarity = rarity;
              if (selectedActionId === ACTION_IDS.HEAL) {
                const yetiMultipler = await contractGameLogic.methods.yetiMultiplier(rarity).call();
                const heal_cost = (2 * 150 * yetiMultipler) / 100; // ! FROM BACKEND
                nftInfo.heal_cost = heal_cost;
              }
              if (
                selectedActionId !== null &&
                stakeTime !== null &&
                selectedActionId !== ACTION_IDS.HEAL
              ) {
                nftInfo.expGained = Math.floor(
                  EXP_RATES[rarity][selectedActionId] *
                    Math.floor((new Date().getTime() / 1000 - stakeTime) / REWARD_TIME)
                );
                nftInfo.expGained =
                  LEVELING_UP[nftInfo.level[0]].EXP > nftInfo.expGained
                    ? nftInfo.expGained
                    : LEVELING_UP[nftInfo.level[0]].EXP;
                nftInfo.frxstGained = Math.floor(
                  FRXST_RATES[rarity][selectedActionId] *
                    (1 + 0.1 * nftInfo.level) *
                    Math.floor((new Date().getTime() / 1000 - stakeTime) / REWARD_TIME)
                );
              }
              const newNftInfo = { ...nftInfo, claimTimeHence, unstakeTime };
              currentStakedTokens.push(newNftInfo);
            }
          }

          setStakedArray(currentStakedTokens);
        }
      } catch (error) {
        handleShowErrorNotification(error);
      }
    }
  };

  const getUserTokens: any = async () => {
    if (provider && walletAddress && !constantsError) {
      try {
        const web3 = new Web3(provider);

        const multicall = new Multicall({ web3Instance: web3, tryAggregate: true });

        const contractCallContext: ContractCallContext[] = [
          {
            reference: 'contractTown',
            contractAddress: YETI_TOWN_ADDRESS,
            abi: abiYeti,
            calls: [
              { reference: 'balanceOf', methodName: 'balanceOf', methodParameters: [walletAddress] }
            ]
          },
          {
            reference: 'contractFrxst',
            contractAddress: FRXST_ADDRESS,
            abi: abiFrxst,
            calls: [
              { reference: 'balanceOf', methodName: 'balanceOf', methodParameters: [walletAddress] }
            ]
          }
        ];

        const results: ContractCallResults = await multicall.call(contractCallContext);

        const yetisBalance = parseInt(
          results.results.contractTown.callsReturnContext[0].returnValues[0].hex,
          16
        );
        const myTotalBalance =
          parseInt(results.results.contractFrxst.callsReturnContext[0].returnValues[0].hex, 16) /
          BALANCE_MULTIPLIER;

        setMyTotalBalance(myTotalBalance);

        if (yetisBalance > 0) {
          const contractCallTokenIDs: ContractCallContext[] = [
            {
              reference: 'contractTown',
              contractAddress: YETI_TOWN_ADDRESS,
              abi: abiYeti,
              calls: Array.from({ length: yetisBalance }, (_, i) => ({
                reference: 'tokenOfOwnerByIndex',
                methodName: 'tokenOfOwnerByIndex',
                methodParameters: [walletAddress, i]
              }))
            }
          ];

          const tokensIDsRes: ContractCallResults = await multicall.call(contractCallTokenIDs);
          const tokensIDs = tokensIDsRes.results.contractTown.callsReturnContext.map(
            (returnContext) => parseInt(returnContext.returnValues[0].hex)
          );

          const gameTokensInfo: {
            edition: string;
            date: number;
            image: string;
            name: string;
            level?: number;
            experience?: number;
          }[] = [];
          for (const tokenID of tokensIDs) {
            if (uninitiatedTokens.includes(tokenID)) {
              continue;
            }
            const tokenURI = IPFS_ROUTE + tokenID + '.json';

            let res;
            try {
              res = await axios.get(tokenURI);
            } catch (e) {
              throw new Error('Something wrong with IPFS, network try again');
            }
            const nftInfo = res.data;
            gameTokensInfo.push(nftInfo);
          }

          const contractCallTokenInfo: ContractCallContext[] = [
            {
              reference: 'levels',
              contractAddress: GAME_LOGIC_ADDRESS,
              abi: abiGame,
              calls: gameTokensInfo.map((nftInfo) => ({
                reference: 'levels',
                methodName: 'levels',
                methodParameters: [nftInfo.edition]
              }))
            },
            {
              reference: 'experience',
              contractAddress: GAME_LOGIC_ADDRESS,
              abi: abiGame,
              calls: gameTokensInfo.map((nftInfo) => ({
                reference: 'experience',
                methodName: 'experience',
                methodParameters: [nftInfo.edition]
              }))
            }
          ];

          const tokenInfoRes: ContractCallResults = await multicall.call(contractCallTokenInfo);
          const tokensLevels = tokenInfoRes.results.levels.callsReturnContext.map(
            (returnContext) => returnContext.returnValues[0]
          );
          const tokensExps = tokenInfoRes.results.experience.callsReturnContext.map(
            (returnContext) => parseInt(returnContext.returnValues[0].hex)
          );

          for (const [index, gameTokenInfo] of gameTokensInfo.entries()) {
            gameTokensInfo[index].image = gameTokenInfo.image.replace(
              'ipfs://',
              'https://ipfs.io/ipfs/'
            );
            gameTokensInfo[index].level = tokensLevels[index];
            gameTokensInfo[index].experience = tokensExps[index];
          }

          setUserNftsData(gameTokensInfo);
        }
      } catch (error) {
        handleShowErrorNotification(error);
      }
    }
  };

  const navigationStakedPrevRef = useRef(null);
  const navigationStakedNextRef = useRef(null);
  const navigationUnstakedPrevRef = useRef(null);
  const navigationUnstakedNextRef = useRef(null);

  const handleStake = async () => {
    if (provider) {
      setFetching(true);
      const abiGame: any = GAME_LOGIC_CONTRACT;
      const web3 = new Web3(provider);

      const contractGameLogic = await new web3.eth.Contract(abiGame, GAME_LOGIC_ADDRESS);

      const content =
        (selectedActionId === ACTION_IDS.HUNT && HuntSplash) ||
        (selectedActionId === ACTION_IDS.FIGHT && FightSplash) ||
        (selectedActionId === ACTION_IDS.GATHER && GatherSplash);

      content &&
        Store.addNotification({
          content: content,
          container: 'top-full',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut']
        });

      try {
        const addManyToPalace = await contractGameLogic.methods
          .addManyToPalace(walletAddress, selectedUnstakedTokens, selectedActionId)
          .send({ from: walletAddress });
        Store.removeAllNotifications();
      } catch (error) {
        handleShowErrorNotification(error);
      } finally {
        setFetching(false);
        initRequests();
      }
    }
  };

  const handleClaim = async () => {
    if (provider) {
      setFetching(true);
      const abiGame: any = GAME_LOGIC_CONTRACT;
      const web3 = new Web3(provider);
      const contractGameLogic = await new web3.eth.Contract(abiGame, GAME_LOGIC_ADDRESS);
      try {
        await contractGameLogic.methods
          .claimMany(selectedStakedTokens, false)
          .send({ from: walletAddress });
      } catch (error) {
        handleShowErrorNotification(error);
      } finally {
        setFetching(false);
      }
      initRequests();
    }
  };

  const handleUnStake = async () => {
    if (provider) {
      setFetching(true);
      const abiGame: any = GAME_LOGIC_CONTRACT;
      const web3 = new Web3(provider);
      const contractGameLogic = await new web3.eth.Contract(abiGame, GAME_LOGIC_ADDRESS);
      try {
        await contractGameLogic.methods
          .claimMany(selectedStakedTokens, true)
          .send({ from: walletAddress });
      } catch (error) {
        handleShowErrorNotification(error);
      } finally {
        setFetching(false);
      }
      initRequests();
    }
  };

  const handleHeal = async () => {
    if (provider) {
      setFetching(true);
      const abi: any = GAME_LOGIC_CONTRACT;
      const web3 = new Web3(provider);
      const contractGameLogic = await new web3.eth.Contract(abi, GAME_LOGIC_ADDRESS);
      try {
        await contractGameLogic.methods.Heal(selectedInHospitalToken).send({ from: walletAddress });
      } catch (error) {
        handleShowErrorNotification(error);
      } finally {
        setFetching(false);
      }
      initRequests();
    }
  };

  const handleClaimSickYeti = async () => {
    if (provider) {
      setFetching(true);
      const abi: any = GAME_LOGIC_CONTRACT;
      const web3 = new Web3(provider);
      const contractGameLogic = await new web3.eth.Contract(abi, GAME_LOGIC_ADDRESS);
      try {
        const ClaimSickYeti = await contractGameLogic.methods
          .ClaimSickYeti(selectedInHospitalToken)
          .send({ from: walletAddress });
      } catch (error) {
        handleShowErrorNotification(error);
      } finally {
        setFetching(false);
      }
      initRequests();
    }
  };

  const handleSelectUnstakedToken = (tokenId: number) => {
    if (selectedUnstakedTokens?.includes(tokenId)) {
      const updatedUnstakedTokens = selectedUnstakedTokens.filter((token) => {
        return token !== tokenId;
      });
      setSelectedUnstakedTokens(updatedUnstakedTokens);
    } else {
      setSelectedUnstakedTokens([...selectedUnstakedTokens, tokenId]);
    }
    setSelectedStakedTokens([]);
  };

  const handleSelectStakedToken = (tokenId: number) => {
    let updatedStakedTokens: number[] = [];
    if (selectedStakedTokens?.includes(tokenId)) {
      updatedStakedTokens = selectedStakedTokens.filter((token) => {
        return token !== tokenId;
      });
      setSelectedStakedTokens(updatedStakedTokens);
    } else {
      updatedStakedTokens = [...selectedStakedTokens, tokenId];
      setSelectedStakedTokens(updatedStakedTokens);
    }
    setSelectedUnstakedTokens([]);
  };

  const handleSelectInHospitalToken = (tokenId: number) => {
    if (selectedInHospitalToken === tokenId) {
      setSelectedInHospitalToken(null);
      setShowClaimSickBtn(false);
    } else {
      setSelectedInHospitalToken(tokenId);
    }
  };

  const initRequests = async () => {
    clearState();
    setFetching(true);
    try {
      const { unstakedTokensCount, stakedTokensCount } = await getTokensCount(
        provider,
        walletAddress
      );
      const newTotalTokensCount = unstakedTokensCount + stakedTokensCount;
      if (totalTokensCount !== newTotalTokensCount) {
        const unInitiatedTokens = await getUninitiatedTokens(provider, walletAddress);
        if (unInitiatedTokens.length > 0) {
          dispatch(setTokensInitiated(false));
          dispatch(setUninitiatedTokens(unInitiatedTokens));
        }
      }
      await getUserTokens();
      await getStakedTokens();
    } catch (error) {
      handleShowErrorNotification(error);
    } finally {
      setFetching(false);
    }
  };

  useEffect(() => {
    if (provider && walletAddress) {
      initRequests();
    }
  }, [provider, walletAddress, selectedActionId, allTokensInitiated, ignoreTokensInitiating]);

  return (
    <>
      {fetching && <LoadingSplash />}
      {!fetching && (
        <div className={`modal-map-field`}>
          <div className="modal-map-field__content">
            <div className="modal-map-field__table">
              <div className="modal-map-table main-table">
                <div className="modal-map-table__title">
                  {selectedActionId === ACTION_IDS.GATHER && 'GATHER'}
                  {selectedActionId === ACTION_IDS.HUNT && 'HUNT'}
                  {selectedActionId === ACTION_IDS.FIGHT && 'DUEL'}
                  {selectedActionId === ACTION_IDS.HEAL && 'HEAL'}
                </div>
                <div className="modal-map-table__header">
                  <div className="modal-map-table__header-row">
                    <div>
                      total staked: <span>{totalStaked}</span>
                    </div>
                    <div>
                      $frxst balance: <span>{Math.round(myTotalBalance)}</span>
                    </div>
                  </div>
                  <div className="modal-map-table__header-row">
                    <div>
                      total unstaked: <span>{userNftsData.length}</span>
                    </div>
                    <div className="modal-map-table__slider "></div>
                  </div>
                </div>
                {stakedArray && stakedArray.length > 0 && (
                  <>
                    <div className="modal-map-table__row-title">
                      {selectedActionId === ACTION_IDS.HEAL ? 'injured:' : 'staked'}
                      {selectedStakedTokens.length > 0 &&
                        ` (${selectedStakedTokens.length} selected):`}
                    </div>
                    <div className="modal-map-table__content modal-map-field__row">
                      {stakedArray.length > 4 && (
                        <>
                          <button
                            className="button-prev"
                            type="button"
                            ref={navigationStakedPrevRef}
                          >
                            <img src={prevArrow} alt="" />
                          </button>
                          <button
                            className="button-next"
                            type="button"
                            ref={navigationStakedNextRef}
                          >
                            <img src={nextArrow} alt="" />
                          </button>
                        </>
                      )}
                      <Swiper
                        slidesPerView={4}
                        spaceBetween={0}
                        watchOverflow={true}
                        navigation={{
                          nextEl: navigationStakedNextRef.current,
                          prevEl: navigationStakedPrevRef.current
                        }}
                        updateOnImagesReady
                      >
                        {stakedArray?.map((nft: any) => {
                          return (
                            <SwiperSlide key={nft.edition}>
                              <div className="chest__row">
                                <NftItem
                                  isSelected={
                                    selectedActionId === ACTION_IDS.HEAL
                                      ? selectedInHospitalToken === nft.edition
                                      : selectedStakedTokens.includes(nft.edition)
                                  }
                                  nft={nft}
                                  onClick={() =>
                                    selectedActionId === ACTION_IDS.HEAL
                                      ? handleSelectInHospitalToken(nft.edition)
                                      : handleSelectStakedToken(nft.edition)
                                  }
                                  showTimer={true}
                                  selectedActionId={selectedActionId}
                                />
                              </div>
                            </SwiperSlide>
                          );
                        })}
                      </Swiper>
                    </div>
                  </>
                )}
                {userNftsData && userNftsData.length > 0 && selectedActionId !== ACTION_IDS.HEAL && (
                  <>
                    <div className="modal-map-table__row-title">
                      unstaked
                      {selectedUnstakedTokens.length > 0 &&
                        ` (${selectedUnstakedTokens.length} selected):`}
                    </div>

                    <div className={`modal-map-table__content modal-map-field__row`}>
                      {userNftsData.length > 4 && (
                        <>
                          <button
                            className="button-prev"
                            type="button"
                            ref={navigationUnstakedPrevRef}
                          >
                            <img src={prevArrow} alt="" />
                          </button>
                          <button
                            className="button-next"
                            type="button"
                            ref={navigationUnstakedNextRef}
                          >
                            <img src={nextArrow} alt="" />
                          </button>
                        </>
                      )}

                      <Swiper
                        slidesPerView={4}
                        spaceBetween={0}
                        watchOverflow={true}
                        navigation={{
                          nextEl: navigationUnstakedNextRef.current,
                          prevEl: navigationUnstakedPrevRef.current
                        }}
                        updateOnImagesReady
                      >
                        {userNftsData?.map((nft: any) => {
                          return (
                            <SwiperSlide key={nft.edition}>
                              <div className="chest__row">
                                <NftItem
                                  isSelected={selectedUnstakedTokens.includes(nft.edition)}
                                  nft={nft}
                                  onClick={() => handleSelectUnstakedToken(nft.edition)}
                                  showTimer={false}
                                />
                              </div>
                            </SwiperSlide>
                          );
                        })}
                      </Swiper>
                    </div>
                  </>
                )}
                <div className="modal-map-table__buttons">
                  {selectedActionId !== ACTION_IDS.HEAL && (
                    <>
                      <button
                        className={`btn-outline ${
                          selectedUnstakedTokens.length > 0 ? '' : 'invisible'
                        }`}
                        type="button"
                        onClick={handleStake}
                      >
                        stake
                      </button>
                      <button
                        className={`btn-outline ${
                          selectedStakedTokens.length > 0 ? '' : 'invisible'
                        }`}
                        data-tip data-for={'nft_tooltip_claim_tooltip'}
                        type="button"
                        onClick={handleClaim}
                        disabled={!showClaimBtn}
                      >
                        claim
                      </button>
                      <Tooltip id={'claim_tooltip'}>
                        <p>It will reset the claim timer.</p>
                      </Tooltip>
                      <div></div>
                      <button
                        className={`btn-outline ${
                          selectedStakedTokens.length > 0 ? '' : 'invisible'
                        }`}
                        type="button"
                        onClick={handleUnStake}
                        disabled={!showUnstakeBtn}
                      >
                        claim & unstake
                      </button>
                    </>
                  )}
                  {selectedActionId === ACTION_IDS.HEAL && selectedInHospitalToken && (
                    <>
                      <button
                        className={`btn-outline ${
                          selectedInHospitalToken && showHealBtn ? '' : 'invisible'
                        }`}
                        type="button"
                        onClick={handleHeal}
                      >
                        heal
                      </button>
                      <div></div>
                      <button
                        className={`btn-outline ${
                          selectedInHospitalToken && showClaimSickBtn ? '' : 'invisible'
                        }`}
                        type="button"
                        onClick={handleClaimSickYeti}
                      >
                        claim
                      </button>
                    </>
                  )}
                </div>
                {stakedArray.length === 0 &&
                  userNftsData.length === 0 &&
                  selectedActionId !== ACTION_IDS.HEAL && (
                    <div className="text-center chest__empty-text">Your chest is empty</div>
                  )}
                {stakedArray.length === 0 && selectedActionId === ACTION_IDS.HEAL && (
                  <div className="text-center chest__empty-text">All yetis are safe</div>
                )}
              </div>
            </div>
            <img
              src={modalMap}
              alt=""
              className={`modal-map-field__img`}
              onLoad={() => setIsMapImageLoaded(true)}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default MapModal;
