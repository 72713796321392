import './styles.scss';

const Footer = () => {
  return (
    <footer className="footer-section" id="stake">
      <div className="page-container page-container--md h-100">
        <div className="footer__content default-text">
          <div className="footer__mid">Yeti Town 2022</div>
          <div className="footer__mid text-right">All Rights Reserved</div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
