import './App.scss';
import { Provider } from 'react-redux';
import { setupStore } from './redux/store';
import Layout from './components/Layout';
import { getDefaultProvider } from 'ethers';
import { NftProvider } from 'use-nft';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';

const store = setupStore();

let persistor = persistStore(store);

const ethersConfig = {
  provider: getDefaultProvider('homestead')
};

function App() {
  return (
    <Provider store={store}>
      <NftProvider fetcher={['ethers', ethersConfig]}>
        <PersistGate loading={null} persistor={persistor}>
          <Layout />
        </PersistGate>
      </NftProvider>
    </Provider>
  );
}

export default App;
