import './styles.scss';
import Map from '../../map';

const GameZone = () => {
  return (
    <section className="gamezone-section" id="stake">
      <div className="page-container page-container--md h-100">
        <div className="heading-1 text-center mb-7 text-blue">ACTIVITIES IN YETI TOWN</div>
        <div className="gamezone">
          <Map />
        </div>
      </div>
    </section>
  );
};

export default GameZone;
