import { useEffect, FC, useState } from 'react';
import intervalToDuration from 'date-fns/intervalToDuration';
import { Tooltip } from './Tooltip';
import { ACTION_IDS } from '../../contracts/actions';

const IMAGES_ROUTE = `${process.env.REACT_APP_IMAGES_ROUTE}`;
interface INftItem {
  nft: any;
  onClick: (nft: any) => void;
  isSelected: boolean;
  showTimer: boolean;
  selectedActionId?: number | null;
}

const NftItem: FC<INftItem> = ({ nft, onClick, isSelected, showTimer, selectedActionId }) => {
  const [displayTime, setDisplayTime] = useState<null | string>(null);
  const [unstakeTime, setUnstakeTime] = useState<null | string>(null);

  const calculateDisplayTime = () => {
    const duration = intervalToDuration({
      start:
        nft?.claimTimeHence && typeof nft.claimTimeHence === 'number' && nft.claimTimeHence > 0
          ? new Date(nft.claimTimeHence * 1000)
          : new Date().getTime(),
      end: new Date().getTime()
    });

    const claimTimeHence =
      nft.claimTimeHence > new Date().getTime() / 1000
        ? `${duration.hours ? duration.hours + 'h' : ''} ${
            duration.minutes ? duration.minutes + 'm' : ''
          } ${!duration.minutes && !duration.hours ? duration.seconds + 's' : ''}`
        : null;
    setDisplayTime(claimTimeHence);

    const durationUnstake = intervalToDuration({
      start:
        nft?.unstakeTime && typeof nft.unstakeTime === 'number' && nft.unstakeTime > 0
          ? new Date(nft.unstakeTime * 1000)
          : new Date().getTime(),
      end: new Date().getTime()
    });

    const daysHours = durationUnstake.days ? durationUnstake.days*24 : 0;
    const unstakeTime =
      nft.unstakeTime > new Date().getTime() / 1000
        ? `${durationUnstake.hours ? (durationUnstake.hours + daysHours) + 'h' : ''} ${
          durationUnstake.minutes ? durationUnstake.minutes + 'm' : ''
          } ${!durationUnstake.minutes && !durationUnstake.hours ? durationUnstake.seconds + 's' : ''}`
        : null;
    setUnstakeTime(unstakeTime);
  };
  useEffect(() => {
    if (showTimer) {
      calculateDisplayTime();
      const setCountdown: ReturnType<typeof setInterval> = setInterval(() => {
        calculateDisplayTime();
      }, 1000);
      return () => clearInterval(setCountdown);
    }
  }, [nft]);

  return (
    <>
      <div data-tip data-for={'nft_tooltip_' + nft.edition} className="nft-item" onClick={onClick}>
        <img
          src={IMAGES_ROUTE + nft.edition + '.png'}
          width={65}
          className={`nft-image ${isSelected ? 'selected' : ''}`}
          alt={"Yeti #" + nft.edition}
        />
        <div>
          <div>id: {nft.edition}</div>
          <div>level: {nft.level}</div>
          <div>exp: {nft.experience}</div>
        </div>
      </div>
      {displayTime && selectedActionId !== null && selectedActionId === ACTION_IDS.HEAL && (
        <Tooltip id={nft.edition}>
          <p>Cost To Heal: {nft.heal_cost} $FRXST</p>
        </Tooltip>
      )}
      {selectedActionId !== null &&
        selectedActionId !== ACTION_IDS.HEAL &&
        nft.hasOwnProperty('expGained') && (
          <Tooltip id={nft.edition}>
            <p>EXP Gained: {nft.expGained}</p>
            <p>$FRXST Gained: {nft.frxstGained}</p>
            {displayTime && (
              <p>Claim time: {displayTime}</p>
            )}
            {unstakeTime && (
              <p>Unstake time: {unstakeTime}</p>
            )}
          </Tooltip>
        )}
    </>
  );
};

export { NftItem };
