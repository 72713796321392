import './styles.scss';

const Phases = () => {
  return (
    <section className="phases-section">
      <div className="page-container page-container--lg" id="roadmap">
        <div className="phases">
          <div className="phases__header pt-100px">
            <h3 className="phases__title heading-1">Roadmap</h3>
          </div>
          <div className="phases__content">
            <div className="phases__item phases-item phases-item--1">
              <div className="heading-2 phases-item__title">phase 1:</div>
              <div className="phases-item__text default-text">mint fully sold out</div>
            </div>
            <div className="phases__item phases-item phases-item--2">
              <div className="heading-2 phases-item__title">phase 2:</div>
              <div className="phases-item__text default-text">launch of staking mechanics</div>
            </div>
            <div className="phases__item phases-item phases-item--3">
              <div className="heading-2 phases-item__title">phase 3:</div>
              <div className="phases-item__text default-text">introduction of $frxst utilities</div>
            </div>
            <div className="phases__item phases-item phases-item--4">
              <div className="heading-2 phases-item__title">beyond:</div>
              <div className="phases-item__text default-text">
                The Evolution Begins - Expanding Our Universe...
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Phases;
