import { FC } from 'react';
import { Dialog } from './index';
import { getConnection } from 'app/redux/reducers/ActionCreators';
import { useAppDispatch } from 'app/hooks/redux';

const ConnectMetaMask: FC = () => {
  const dispatch = useAppDispatch();
  const handleGetConnection = () => dispatch(getConnection());

  return (
    <Dialog>
      <div className="dialog__content">
        <p>You need to connect to MetaMask</p>
        <button className="btn-outline" type="button" onClick={handleGetConnection}>
          Connect
        </button>
      </div>
    </Dialog>
  );
};

export default ConnectMetaMask;
