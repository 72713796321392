import fightNotificationImg from '../../../assets/images/fight-notification.gif';

const FightSplash = () => {
  return (
    <div className="full-width-notification">
      <img className="full-width-notification__img" src={fightNotificationImg} alt="Fight" />
    </div>
  );
};

export default FightSplash;
