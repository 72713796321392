import { FC } from 'react';
import ReactTooltip from 'react-tooltip';
import ReactDOM from 'react-dom';
import './tooltip.scss';

interface ITooltip {
  id: string;
}

export const Tooltip: FC<ITooltip> = ({ id, children }) => {
  return ReactDOM.createPortal(
    <ReactTooltip id={'nft_tooltip_' + id} place="top" type="warning" effect="float">
      {children}
    </ReactTooltip>,
    document.body
  );
};
