import Web3 from 'web3';
import { Multicall, ContractCallResults, ContractCallContext } from 'ethereum-multicall';
import abiYeti from '../contracts/YetiTown.json';
import abiGame from '../contracts/GameLogicV2.json';

const YETI_TOWN_ADDRESS = `${process.env.REACT_APP_YETI_TOWN_ADDRESS}`;
const GAME_LOGIC_ADDRESS = `${process.env.REACT_APP_GAME_LOGIC_ADDRESS}`;

export const getTokensCount = async (
  provider: any,
  walletAddress: String | null
): Promise<{
  unstakedTokensCount: number;
  stakedTokensCount: number;
}> => {
  const web3 = new Web3(provider);

  const multicall = new Multicall({ web3Instance: web3, tryAggregate: true });

  const contractCallContext: ContractCallContext[] = [
    {
      reference: 'contractTown',
      contractAddress: YETI_TOWN_ADDRESS,
      abi: abiYeti,
      calls: [
        { reference: 'balanceOf', methodName: 'balanceOf', methodParameters: [walletAddress] },
        {
          reference: 'isApprovedForAll',
          methodName: 'isApprovedForAll',
          methodParameters: [walletAddress, GAME_LOGIC_ADDRESS]
        }
      ]
    },
    {
      reference: 'contractGameLogic',
      contractAddress: GAME_LOGIC_ADDRESS,
      abi: abiGame,
      calls: [
        {
          reference: 'getStakedTokens',
          methodName: 'getStakedTokens',
          methodParameters: [walletAddress]
        }
      ]
    }
  ];

  const results: ContractCallResults = await multicall.call(contractCallContext);
  const unstakedTokensCount = parseInt(
    results.results.contractTown.callsReturnContext[0].returnValues[0].hex,
    16
  );
  const stakedTokens = results.results.contractGameLogic.callsReturnContext[0].returnValues.map(
    (returnValue) => parseInt(returnValue.hex)
  );
  return { unstakedTokensCount, stakedTokensCount: stakedTokens.length };
};
