import { FC } from 'react';
import ModalContainer from '../../containers/modal';
import LoadingSplash from '../map/splashScreens/LoadingSplash';
import ConnectMetaMask from '../dialog/ConnectMetaMask';
import DisabledMetaMask from '../dialog/DisabledMetaMask';
import InitiateGame from '../dialog/InitiateGame';
import { useAppSelector } from '../../hooks/redux';
import { useProvider } from '../../hooks/provider';

interface IWithErrorCheckModals {
  modalOpen: boolean;
  onCloseModal: () => void;
}

const WithErrorCheckModals: FC<IWithErrorCheckModals> = ({ modalOpen, children, onCloseModal }) => {
  const provider = useProvider();

  const { gameAllowed, gameApproved, allTokensInitiated, ignoreTokensInitiating, loading } =
    useAppSelector((state) => state.TokensReducer);
  const { token } = useAppSelector((state) => state.AuthReducer);
  return (
    <>
      {loading && <LoadingSplash />}
      <ModalContainer
        className={'dialog-modal-wrapper'}
        isOpen={modalOpen && !provider}
        closeModal={onCloseModal}
      >
        <DisabledMetaMask />
      </ModalContainer>
      <ModalContainer
        isOpen={modalOpen && !token && !!provider}
        className={'dialog-modal-wrapper'}
        closeModal={onCloseModal}
      >
        <ConnectMetaMask />
      </ModalContainer>
      <ModalContainer
        className={'dialog-modal-wrapper'}
        isOpen={
          modalOpen &&
          token &&
          provider &&
          (!gameAllowed || !gameApproved || (!allTokensInitiated && !ignoreTokensInitiating))
        }
        closeModal={onCloseModal}
      >
        <InitiateGame />
      </ModalContainer>
      <ModalContainer
        className={''}
        isOpen={
          modalOpen &&
          token &&
          provider &&
          gameApproved &&
          gameAllowed &&
          (allTokensInitiated || ignoreTokensInitiating)
        }
        closeModal={onCloseModal}
      >
        <>{children}</>
      </ModalContainer>
    </>
  );
};

export default WithErrorCheckModals;
