import './styles.scss';
import deviants from '../../../assets/images/deviants.gif';
import deviantsWebp from '../../../assets/images/deviants.webp';
import nobles from '../../../assets/images/nobles.gif';
import noblesWebp from '../../../assets/images/nobles.webp';
import eternals from '../../../assets/images/eternals.gif';
import eternalsWebp from '../../../assets/images/eternals.webp';

const Clans = () => {
  return (
    <section className="clans-section-wrapper" id="origin">
      <div className="page-container page-container--lg">
        <div className="clans-section">
          <p className="text-blue text-center mt-4 mb-7 text-white heading-1">choose your yeti</p>
          <div className="clans-section__content">
            <div className="clans-section__item">
              <picture className="clans-section__img">
                <source src={deviants} type="image/gif" />
                <img
                  src={deviantsWebp}
                  alt="Deviants"
                  className="clans-section__img"
                  loading="lazy"
                />
              </picture>
              <div className="heading-2 text-blue mt-3 text-center">Deviants</div>
            </div>
            <div className="clans-section__item">
              <picture className="clans-section__img">
                <source src={nobles} type="image/gif" />
                <img src={noblesWebp} alt="nobles" className="clans-section__img" loading="lazy" />
              </picture>
              <div className="heading-2 text-blue mt-3 text-center">Nobles</div>
            </div>
            <div className="clans-section__item">
              <picture className="clans-section__img">
                <source src={eternals} type="image/gif" />
                <img
                  src={eternalsWebp}
                  alt="Deviants"
                  className="clans-section__img"
                  loading="lazy"
                />
              </picture>
              <div className="heading-2 text-blue mt-3 text-center">Eternals</div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Clans;
