import './styles.scss';

const Info = () => {
  return (
    <section className="info-section">
      <div className="page-container page-container--md">
        <div className="info-section__content">
          <div className="info-section-item">
            <div className="heading-2 info-section-item__title">
              <span className="system-font pr-2">❄</span>1 $FRXST = 1 $FRXST.
            </div>
          </div>
          <div className="info-section-item">
            <div className="heading-2 info-section-item__title">
              <span className="system-font pr-2">💰</span>Yield
            </div>
            <div className="info-section-item__text default-text">
              every Yeti yields $FRXST and EXP depending on the activity they choose to participate
              in and their collector's grade.
            </div>
          </div>
          <div className="info-section-item">
            <div className="heading-2 info-section-item__title">
              <span className="system-font pr-2">🔥</span>Burn{' '}
            </div>
            <div className="info-section-item__text default-text">
              burning $FRXST in-game will allow you to level up, but beyond the game - the $FRXST
              token unlocks various utilities and perks for holders.
            </div>
          </div>
          <div className="info-section-item info-section-item">
            <div className="heading-2 info-section-item__title">
              <span className="system-font pr-2">🔨</span>Building out the next collection.
            </div>
            <div className="info-section-item__text default-text">
              Yeti holders can burn $FRXST to mint future collections for free. This collection will
              be separate to prevent dilution of the Genesis collection.
            </div>
          </div>
          <div className="info-section-item info-section-item">
            <div className="heading-2 info-section-item__title">
              <span className="system-font pr-2">🤝</span>Collaborations & partnerships.
            </div>
            <div className="info-section-item__text default-text">
              Yeti holders can burn $FRXST to gain access to exclusive and in-demand drops.
            </div>
          </div>
          <div className="info-section-item info-section-item">
            <div className="heading-2 info-section-item__title">
              <span className="system-font pr-2">📈</span>Exclusive alpha-filled community.{' '}
            </div>
            <div className="info-section-item__text default-text">
              What makes any NFT project valuable is the community, and the alpha (profit) that can
              be gleaned off from insights. Holders will have access to exclusive channels where
              they will receive insights and analysis into crypto & NFT opportunities.
            </div>
          </div>
          <div className="info-section-item">
            <div className="heading-2 info-section-item__title">
              <span className="system-font pr-2">🧸</span>Merchandise & toys
            </div>
            <div className="info-section-item__text default-text">
              our team has experience in running 2 successful clothing brands, holders can expect to
              see merchandise in the future.
            </div>
          </div>
          <div className="info-section-item">
            <div className="heading-2 info-section-item__title">
              <span className="system-font pr-2">🕹</span>Expansion in the Metaverse
            </div>
            <div className="info-section-item__text default-text">
              you can use your $FRXST to unlock a 3D / Voxel version of your Yeti, which can be used
              for the Metaverse. We plan on allowing our Yetis to interact with Sandbox and other
              blockchain games.
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Info;
