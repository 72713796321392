import { useState, useEffect } from 'react';
import { useAppDispatch } from './redux';
import { setMetaMaskEnabled } from '../redux/reducers/AuthSlice';
import Web3 from 'web3';

const useProvider = (): any => {
  const [provider, setProvider] = useState(null);

  const dispatch = useAppDispatch();

  const detectProvider = (): any => {
    const newProvider = window?.ethereum || (window?.web3 && window.web3.currentProvider);
    return newProvider;
  };

  useEffect(() => {
    const newProvider = detectProvider();
    if (newProvider) {
      dispatch(setMetaMaskEnabled());
    }
    setProvider(newProvider);
  }, []);
  return provider;
};

const useMainNetProvider = (): any => {
  const [provider, setProvider] = useState(null);

  const dispatch = useAppDispatch();

  const detectProvider = (): any => {
    const address = process.env.REACT_APP_M_ETH_NETWORK_ADDRESS;

    const newProvider = new Web3.providers.HttpProvider(`${address}`);
    return newProvider;
  };

  useEffect(() => {
    const newProvider = detectProvider();
    if (newProvider) {
      dispatch(setMetaMaskEnabled());
    }
    setProvider(newProvider);
  }, []);
  return provider;
};

const useLocalProvider = (): any => {
  const [provider, setProvider] = useState(null);

  const dispatch = useAppDispatch();

  const detectProvider = (): any => {
    const address = process.env.REACT_APP_L_ETH_NETWORK_ADDRESS;

    const newProvider = new Web3.providers.HttpProvider(`${address}`);
    return newProvider;
  };

  useEffect(() => {
    const newProvider = detectProvider();
    if (newProvider) {
      dispatch(setMetaMaskEnabled());
    }
    setProvider(newProvider);
  }, []);
  return provider;
};

const useRinkebyProvider = (): any => {
  const [provider, setProvider] = useState(null);

  const dispatch = useAppDispatch();

  const detectProvider = (): any => {
    const address = process.env.REACT_APP_R_ETH_NETWORK_ADDRESS;

    const newProvider = new Web3.providers.HttpProvider(`${address}`);
    return newProvider;
  };

  useEffect(() => {
    const newProvider = detectProvider();
    if (newProvider) {
      dispatch(setMetaMaskEnabled());
    }
    setProvider(newProvider);
  }, []);
  return provider;
};

export { useProvider, useMainNetProvider, useRinkebyProvider, useLocalProvider };
