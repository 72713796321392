import { configureStore, combineReducers } from '@reduxjs/toolkit';
import AuthReducer from './reducers/AuthSlice';
import TokensReducer from './reducers/TokensSlice';
import ConstantsReducer from './reducers/ConstantsSlice';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const rootReducer = combineReducers({
  AuthReducer,
  TokensReducer,
  ConstantsReducer
});

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['AuthReducer']
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const setupStore = () => {
  return configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false
      }),
    devTools: process.env.REACT_APP_NODE_ENV !== 'production'
  });
};

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];
