import { FC } from 'react';
import { Dialog } from './index';

const DisabledMetaMask: FC = () => {
  return (
    <Dialog>
      <div className="dialog__content">
        <p>You need to install MetaMask</p>
      </div>
    </Dialog>
  );
};

export default DisabledMetaMask;
