import huntNotificationImg from '../../../assets/images/hunt-notification.gif';

const HuntSplash = () => {
  return (
    <div className="full-width-notification">
      <img className="full-width-notification__img" src={huntNotificationImg} alt="Hunt" />
    </div>
  );
};

export default HuntSplash;
