import { createAsyncThunk } from '@reduxjs/toolkit';

export const getConnection = createAsyncThunk('user/getConnection', async (_, thunkAPI) => {
  try {
    // Request account access
    const provider = window.ethereum;

    if (!provider) {
      throw new Error('Metamask wallet is not found');
    }

    const accounts = await provider.request({ method: 'eth_requestAccounts' });
    const isConnected = provider.isConnected();

    return { isConnected, accounts };
  } catch ({ message }) {
    // User denied access
    return thunkAPI.rejectWithValue(message);
  }
});
