import React, { FC, ReactComponentElement, useLayoutEffect } from 'react';
import ReactDOM from 'react-dom';
import './style.scss';

interface IModal {
  isOpen: any;
  children: ReactComponentElement<any>;
  closeModal: () => void;
  className: string;
}

const ModalContainer: FC<IModal> = ({ isOpen, children, closeModal, className }) => {
  useLayoutEffect(() => {
    document.body.style.overflow = isOpen ? 'hidden' : 'auto';
  }, [isOpen]);

  window.addEventListener('keydown', (e) => {
    if (e.keyCode == 27) {
      closeModal();
    }
  });

  return (
    isOpen &&
    ReactDOM.createPortal(
      <div className={`modal-wrapper ${className}`}>
        <div className="modal">
          <button className="modal__close" type="button" onClick={closeModal}>
            Close
          </button>
          {children}
        </div>
      </div>,
      document.body
    )
  );
};

export default ModalContainer;
