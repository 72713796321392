import './styles.scss';
import chestImg from '../../../assets/images/chest.gif';
import chestImgWebp from '../../../assets/images/chest.webp';
import { useState } from 'react';
import 'swiper/swiper-bundle.css';
import 'swiper/swiper.min.css';
import ChestModal from './ChestModal';
import WithErrorCheckModals from '../../errorCheck';

const Chest = () => {
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  return (
    <>
      <section className="chest-section-wrapper">
        <div className="page-container page-container--lg">
          <div className="chest">
            <button className="btn-unstyled" type="button" onClick={() => setModalOpen(true)}>
              <picture className="chest__img">
                <source src={chestImg} type="image/gif" />
                <img src={chestImgWebp} className="chest__img" alt="map" />
              </picture>
            </button>
          </div>
        </div>
      </section>
      <WithErrorCheckModals modalOpen={modalOpen} onCloseModal={handleCloseModal}>
        <ChestModal />
      </WithErrorCheckModals>
    </>
  );
};

export default Chest;
