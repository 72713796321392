import { FC, useState } from 'react';
import { Dialog } from './index';
import './index.scss';
import { Multicall, ContractCallResults, ContractCallContext } from 'ethereum-multicall';
import axios from 'axios';
import { useAppDispatch, useAppSelector } from 'app/hooks/redux';
import { useProvider } from 'app/hooks/provider';
import GAME_LOGIC_CONTRACT from '../../contracts/GameLogicV2.json';
import YETI_TOWN_CONTRACT from '../../contracts/YetiTown.json';
import FRXST_CONTRACT from '../../contracts/FRXST.json';
import Web3 from 'web3';
import {
  setTokensInitiated,
  setGameAllowed,
  setGameApproved,
  setIgnoreTokensInitiating
} from 'app/redux/reducers/TokensSlice';
import { handleShowErrorNotification } from '../../helpers/showError';
import LoadingSplash from '../map/splashScreens/LoadingSplash';

const YETI_TOWN_ADDRESS = `${process.env.REACT_APP_YETI_TOWN_ADDRESS}`;
const GAME_LOGIC_ADDRESS = `${process.env.REACT_APP_GAME_LOGIC_ADDRESS}`;
const FRXST_ADDRESS = `${process.env.REACT_APP_FRXST_ADDRESS}`;
const RARITY_CHECK_ADDRESS = `${process.env.REACT_APP_RARITY_CHECK_ADDRESS}`;
const FRXST_ALLOWANCE = `${process.env.REACT_APP_ALLOWANCE}`;
const IPFS_ROUTE = `${process.env.REACT_APP_IPFS_ROUTE}`;

const abiYeti: any = YETI_TOWN_CONTRACT;
const abiGame: any = GAME_LOGIC_CONTRACT;
const abiFrxst: any = FRXST_CONTRACT;

const InitiateGame: FC = () => {
  const dispatch = useAppDispatch();
  const provider = useProvider();

  const [loading, setLoading] = useState<boolean>(false);

  const initLoading = useAppSelector((state) => state.TokensReducer.loading);

  const walletAddress = useAppSelector((state) => state.AuthReducer.token);

  const gameApproved = useAppSelector((state) => state.TokensReducer.gameApproved);
  const gameAllowed = useAppSelector((state) => state.TokensReducer.gameAllowed);
  const allTokensInitiated = useAppSelector((state) => state.TokensReducer.allTokensInitiated);
  const totalTokensCount: Number = useAppSelector((state) => state.TokensReducer.totalTokensCount);
  const uninitiatedTokens = useAppSelector((state) => state.TokensReducer.uninitiatedTokens);

  const handleApproveGame = async (): Promise<void> => {
    const web3 = new Web3(provider);
    const contractTown = await new web3.eth.Contract(abiYeti, `${YETI_TOWN_ADDRESS}`);
    const multicall = new Multicall({ web3Instance: web3, tryAggregate: true });

    const contractCallContext: ContractCallContext[] = [
      {
        reference: 'contractTown',
        contractAddress: YETI_TOWN_ADDRESS,
        abi: abiYeti,
        calls: [
          {
            reference: 'isApprovedForAll',
            methodName: 'isApprovedForAll',
            methodParameters: [walletAddress, GAME_LOGIC_ADDRESS]
          }
        ]
      }
    ];

    const results: ContractCallResults = await multicall.call(contractCallContext);

    const isApprovedForAll = results.results.contractTown.callsReturnContext[0].returnValues[0];

    if (!isApprovedForAll) {
      try {
        setLoading(true);
        await contractTown.methods
          .setApprovalForAll(GAME_LOGIC_ADDRESS, true)
          .send({ from: walletAddress });
        dispatch(setGameApproved(true));
      } catch (error) {
        handleShowErrorNotification(error);
        dispatch(setGameApproved(false));
      }
    }
    setLoading(false);
  };

  const handleAllowGame = async (): Promise<void> => {
    const web3 = new Web3(provider);
    const contractFrxst = await new web3.eth.Contract(abiFrxst, `${FRXST_ADDRESS}`);
    const multicall = new Multicall({ web3Instance: web3, tryAggregate: true });

    const contractCallContext: ContractCallContext[] = [
      {
        reference: 'contractFrxst',
        contractAddress: FRXST_ADDRESS,
        abi: abiFrxst,
        calls: [
          {
            reference: 'allowance',
            methodName: 'allowance',
            methodParameters: [walletAddress, `${GAME_LOGIC_ADDRESS}`]
          }
        ]
      }
    ];

    const results: ContractCallResults = await multicall.call(contractCallContext);

    const allowance = results.results.contractFrxst.callsReturnContext[0].returnValues[0].hex;

    const frxstAllowedToCharge = allowance === FRXST_ALLOWANCE;

    if (!frxstAllowedToCharge) {
      try {
        setLoading(true);
        await contractFrxst.methods
          .approve(GAME_LOGIC_ADDRESS, FRXST_ALLOWANCE)
          .send({ from: walletAddress });
        dispatch(setGameAllowed(true));
      } catch (error) {
        handleShowErrorNotification(error);
        dispatch(setGameAllowed(false));
      }
    }
    setLoading(false);
  };

  const handleInitiateTokens = async (): Promise<void> => {
    setLoading(true);
    const web3 = new Web3(provider);
    const contractGameLogic = await new web3.eth.Contract(abiGame, `${GAME_LOGIC_ADDRESS}`);
    const multicall = new Multicall({ web3Instance: web3, tryAggregate: true });

    const contractCallContext: ContractCallContext[] = [
      {
        reference: 'contractTown',
        contractAddress: YETI_TOWN_ADDRESS,
        abi: abiYeti,
        calls: [
          { reference: 'balanceOf', methodName: 'balanceOf', methodParameters: [walletAddress] },
          {
            reference: 'isApprovedForAll',
            methodName: 'isApprovedForAll',
            methodParameters: [walletAddress, GAME_LOGIC_ADDRESS]
          }
        ]
      },
      {
        reference: 'contractFrxst',
        contractAddress: FRXST_ADDRESS,
        abi: abiFrxst,
        calls: [
          {
            reference: 'allowance',
            methodName: 'allowance',
            methodParameters: [walletAddress, `${GAME_LOGIC_ADDRESS}`]
          }
        ]
      }
    ];

    const results: ContractCallResults = await multicall.call(contractCallContext);

    const yetisBalance = parseInt(
      results.results.contractTown.callsReturnContext[0].returnValues[0].hex,
      16
    );
    const contractCallTokenIDs: ContractCallContext[] = [
      {
        reference: 'contractTown',
        contractAddress: YETI_TOWN_ADDRESS,
        abi: abiYeti,
        calls: Array.from({ length: yetisBalance }, (_, i) => ({
          reference: 'tokenOfOwnerByIndex',
          methodName: 'tokenOfOwnerByIndex',
          methodParameters: [walletAddress, i]
        }))
      }
    ];

    const tokensIDsRes: ContractCallResults = await multicall.call(contractCallTokenIDs);
    const tokensIDs = tokensIDsRes.results.contractTown.callsReturnContext.map((returnContext) =>
      parseInt(returnContext.returnValues[0].hex)
    );
    const gameTokensInfo: { edition: string; date: number; image: string; name: string }[] = [];

    for (const tokenID of tokensIDs) {
      const tokenURI = IPFS_ROUTE + tokenID + '.json';

      let res;
      try {
        res = await axios.get(tokenURI);
      } catch (e) {
        throw new Error('Something wrong with IPFS, network try again');
      }
      const nftInfo = res.data;
      gameTokensInfo.push(nftInfo);
    }
    const contractCallInitiateGameTokens: ContractCallContext[] = [
      {
        reference: 'contractTown',
        contractAddress: GAME_LOGIC_ADDRESS,
        abi: abiGame,
        calls: gameTokensInfo.map((nftInfo) => ({
          reference: 'initiateGame',
          methodName: 'initiateGame',
          methodParameters: [nftInfo.edition]
        }))
      }
    ];

    const initiateGameTokensRes: ContractCallResults = await multicall.call(
      contractCallInitiateGameTokens
    );
    const initiateGameTokens = initiateGameTokensRes.results.contractTown.callsReturnContext.map(
      (returnContext) => returnContext.returnValues[0]
    );

    const unInitatedTokens = [];

    for (const [index, tokenInitiated] of initiateGameTokens.entries()) {
      if (!tokenInitiated) {
        const edition = gameTokensInfo[index]?.edition;
        const {
          data: { rarity, signature }
        } = await axios.post(RARITY_CHECK_ADDRESS, {
          tokenID: edition
        });
        unInitatedTokens.push([edition, rarity, signature]);
      }
    }

    if (unInitatedTokens.length > 0) {
      try {
        await contractGameLogic.methods
          .initiateGameAt(unInitatedTokens)
          .send({ from: walletAddress });
        dispatch(setTokensInitiated(true));
      } catch (error) {
        handleShowErrorNotification(error);
        dispatch(setTokensInitiated(false));
      }
    }
    setLoading(false);
  };

  const handleSkipInitiateTokens = async (): Promise<void> => {
    dispatch(setIgnoreTokensInitiating(true));
  };

  const showSkip = +totalTokensCount - uninitiatedTokens.length > uninitiatedTokens.length; // ONLY IF WE INITIATED OTHER TOKENS PREVIOUSLY

  return (
    <>
      {(loading || initLoading) && <LoadingSplash />}
      {totalTokensCount > 0 && (!gameApproved || !gameAllowed || !allTokensInitiated) && (
        <Dialog>
          <div className="dialog__content init-game-dialog">
            <p className="init-game-dialog__header-1">TO START A GAME:</p>
            <p className="init-game-dialog__header-2">INITIATE GAME CONTRACT:</p>
            <button
              className="btn-outline init-game-dialog__btn"
              onClick={handleApproveGame}
              disabled={gameApproved}
              type="button"
            >
              ALLOW
            </button>
            <p className="init-game-dialog__header-2">ALLOW $FRXST:</p>
            <button
              className="btn-outline init-game-dialog__btn"
              onClick={handleAllowGame}
              disabled={!gameApproved || gameAllowed}
              type="button"
            >
              ALLOW
            </button>
            <p className="init-game-dialog__header-2">AND INITIATE NFTS:</p>
            {showSkip && (
              <p className="init-game-dialog__header-3">
                We noticed you have a new NFT that is uninitiated, please initiate it
              </p>
            )}
            <div className="flex-center">
              <button
                className="btn-outline init-game-dialog__btn"
                onClick={handleInitiateTokens}
                disabled={!gameApproved || !gameAllowed || allTokensInitiated}
                type="button"
              >
                ALLOW
              </button>
              {showSkip && (
                <button
                  className="btn-outline init-game-dialog__btn"
                  onClick={handleSkipInitiateTokens}
                  type="button"
                  disabled={!gameAllowed || !gameApproved}
                >
                  Skip
                </button>
              )}
            </div>
          </div>
        </Dialog>
      )}
      {totalTokensCount === 0 && (gameApproved || gameAllowed || allTokensInitiated) && (
        <Dialog>
          <div className="dialog__content">
            <p className="init-game-dialog__header-1">You don’t have Yetis</p>
          </div>
        </Dialog>
      )}
    </>
  );
};

export default InitiateGame;
