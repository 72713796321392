import './styles.scss';

const HowItStarted = () => {
  return (
    <section className="how-it-started-section" id="origin">
      <div className="page-container page-container--md h-100">
        <div className="how-it-started">
          <p className="text-blue text-center mb-2 how-it-started__title heading-1">
            how it started
          </p>
          <div className="text-center how-it-started__underline mb-2 default-text">
            THE TOYMAKER CREATED 4,444 YETIS WITH 3 COLLECTOR GRADES,
            <div className=""> DEVIANTS, NOBLES & ETERNALS.</div>
          </div>
          <p className="text-center default-text">
            TO SURVIVE AND THRIVE IN YETI TOWN. YETIS CAN EITHER HARVEST, HUNT OR FIGHT TO YIELD
            &FRXST AND EXP. ALLOWING THEM TO LEVEL UP AND UNLOCK NEW UTILITIES.
          </p>
        </div>
      </div>
    </section>
  );
};

export default HowItStarted;
