import { createSlice } from '@reduxjs/toolkit';

interface ConstantsState {
  MINIMUM_TO_EXIT: number;
  INJURY_TIME: number;
  REWARD_TIME: number;
  error: boolean;
}

const initialState: ConstantsState = {
  MINIMUM_TO_EXIT: 0,
  INJURY_TIME: 0,
  REWARD_TIME: 0,
  error: false
};

export const ConstantsSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setConstants: (state, action) => {
      state.MINIMUM_TO_EXIT = action.payload.MINIMUM_TO_EXIT;
      state.INJURY_TIME = action.payload.INJURY_TIME;
      state.REWARD_TIME = action.payload.REWARD_TIME;
      state.error = false;
    },
    setConstantsError: (state, action) => {
      state.error = action.payload;
    }
  },
  extraReducers: {}
});
export const { setConstants, setConstantsError } = ConstantsSlice.actions;

export default ConstantsSlice.reducer;
