import huntNotificationImg from '../../../assets/icons/loading.gif';

const LoadingSplash = () => {
  return (
    <div className="full-width-notification loading-notification">
      <img className="full-width-notification__img" src={huntNotificationImg} alt="Hunt" />
    </div>
  );
};

export default LoadingSplash;
