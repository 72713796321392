interface ILEVELING_UP {
[key: number]: { FRXST: number; EXP: number };
}

const LEVELING_UP: ILEVELING_UP = {
0: {
    FRXST: 1000000000,
    EXP: 1000000000
},
1: {
    FRXST: 48,
    EXP: 50
},
2: {
    FRXST: 210,
    EXP: 200
},
3: {
    FRXST: 512,
    EXP: 450
},
4: {
    FRXST: 980,
    EXP: 800
},
5: {
    FRXST: 2100,
    EXP: 1600
},
6: {
    FRXST: 3430,
    EXP: 2450
},
7: {
    FRXST: 4760,
    EXP: 3200
},
8: {
    FRXST: 6379,
    EXP: 4050
},
9: {
    FRXST: 8313,
    EXP: 5000
}
};

export default LEVELING_UP;