import { useEffect, FC, useState, ReactComponentElement } from 'react';
import modalMap from '../../assets/images/modal-map.png';
import './index.scss';

interface IDialog {
  children: ReactComponentElement<any>;
  loading?: boolean;
}

const Dialog: FC<IDialog> = ({ children, loading }) => {
  return (
    <div className={`dialog ${loading ? 'loading' : ''}`}>
      <img src={modalMap} className="dialog__img" alt="" />
      {children}
    </div>
  );
};

export { Dialog };
